<template>
    <!-- <Lightbox ref="popup" :media="popup" /> -->

    <div id="headerDiv" class="w-full">
        <div class="bg-white-70 h-full w-full text-center pt-16 px-6 lg:px-20 xl:px-40 lg:pt-20 xl:pt-20 relative">
            <h1 id="landingTitle" class="font-extrabold text-5xl leading-tight lg:leading-tight lg:text-6xl tracking-wide uppercase lg:hidden">P3-PSLE<br class="lg:hidden" />N Level<br />O Level Math Tuition</h1>
            <h1 id="landingTitle" class="xl:max-w-5xl flex-col font-extrabold text-5xl leading-tight lg:leading-tight lg:text-6xl tracking-wide uppercase w-4/5 mx-auto hidden lg:block">
                <p class="flex justify-between"><span class="">P3-PSLE</span><span class="">N Level Math</span></p>
                <p class="flex justify-between"><span class="">EMath</span><span class="">AMath</span></p>
                <p>O LEVEL MATH TUITION</p>
            </h1>
            <p id="landingText" class="text-gray-600 text-xl lg:text-3xl mt-6 lg:mt-10 mb-12">Learn from Our Math Specialists to Improve 3-4 Grades Now</p>
            <router-link to="/contact" id="landingBtn" class="py-3 px-5 btn opacity-0 text-xl inline-block transform -translate-y-1/2">Get In Touch!</router-link>
        </div>
    </div>
    
    <div class="bg-gray-800 text-white pt-14 pb-16 lg:px-40 px-6">
        <div class="text-center text-xl lg:w-1/2 mx-auto">
            <p>All students deserve a chance to score in Mathematics.<br /><br />We simplify math concepts with 24/7 online consultations to help achieve desired math results.<br /><br /></p>
            <p>We are also the first N-Level-Focus Math Tuition Centre.<br /><br />Join us today!</p>
        </div>
    </div>

    <div class="bg-gray-50 pt-12 pb-16 lg:px-40 px-6">
        <h2 class="col-span-4 text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase mb-14">See What Parents and Students Say on Google</h2>
        <div class="grid grid-cols-4 gap-12 mx-auto mb-12 lg:mb-0 carousel relative lg:rounded-lg">
            <img src="/assets/testimonials/google1.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/google2.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/google3.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/google4.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/google5.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/google6.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
        </div>
    </div>

    <div id="results" class="bg-gray-800 text-white pt-14 pb-14 lg:pb-20 lg:px-40 px-6">
        <h2 class="text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase mb-8">SAVE YOUR CHILD’S MATH WITH US</h2>
        <h4 class="text-center lg:w-2/3 mx-auto text-lg">Join us for a trial today! Receive a diagnosis report from us.</h4>


        <video class="col-span-4 lg:w-2/3 mx-auto mt-12 rounded-lg" playsinline autoplay loop muted>
            <source src="/assets/timelapse.mp4" type="video/mp4">
        </video>

       <div class="col-span-4 text-center mt-10"><router-link to="/contact" class="mr-2 lg:mr-4 px-5 py-1 inline-block border rounded-lg hover:bg-white hover:text-gray-800 transition ease-linear mt-10">Sign Me Up!</router-link></div>
    </div>
    
    <div class="bg-gray-50 pt-14 pb-14 lg:pb-10 lg:px-40 px-6">
        <div class="mb-14">
            <h3 class="text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase mb-8">See other students we saved</h3>
            <h4 class="text-center lg:w-2/3 mx-auto text-lg">For PSLE, O Level AMath, O Level EMath</h4>
        </div>
        <div class="grid grid-cols-3 gap-12 mx-auto mb-12 lg:mb-0 carousel relative lg:rounded-lg">
            <img src="/assets/testimonials/testimonial1.png" alt="Testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/testimonial4.png" alt="Testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/testimonial11.png" alt="Testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
        </div>

        <div class="col-span-1 text-center mt-14">
            <router-link to="/results" class="mr-2 lg:mr-4 px-5 py-1 inline-block border rounded-lg btn transition ease-linear">Other Students Saved</router-link>
            <router-link to="/contact" class="mt-6 lg:mt-0 mr-2 lg:mr-4 px-5 py-1 inline-block border rounded-lg btn transition ease-linear">Trial with Us Now!</router-link>
        </div>
    </div>

    <div class="bg-gray-800 text-white pt-14 pb-14 lg:pb-20 lg:px-40 px-6">
        <div class="text-center mb-8">
            <h3 class="font-extrabold tracking-wide text-3xl lg:text-4xl uppercase lg:w-1/2 mx-auto">Expert young teachers to connect with your child</h3>
        </div>
        <p class="text-center lg:w-2/3 mx-auto text-lg mb-14">School friends since 2010, we create a dynamic learning environment like no other.</p>
        <div class="grid grid-cols-4 gap-6 lg:gap-20 mx-auto mb-12 lg:mb-0 relative lg:rounded-lg overflow-hidden">
            <img src="\assets\mrLai.jpg" alt="Mr Lai picture" class="tutorPic col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <div class="col-span-4 lg:col-span-2 mt-10">
                <p class="font-bold text-3xl">Zhi Khong / Zac (Mr. Lai)</p>
                <p class="italic mt-4 text-lg">Co-Founder, Head of Curriculum</p>
                <p class="italic text-lg">Principal Tutor (O/A Levels)</p>

                <div class="mt-8">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Background</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>NUS Merit Scholar (Top 1%), Double Degree</div>
                    <div class="pl-10">Bachelor of Business (Finance), NUS</div>
                    <div class="pl-10 mt-1">Bachelor of Arts (Economics), NUS</div>                    
                    <div class="flex text-left items-center mt-6"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>NTUC Income Prize (Top Student in FIN3703: Financial Markets)</div>
                </div>

                <div id="laiRecord" class="mt-6">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Track Record</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>6+</b> years of teaching</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>86%</b> distinction across all students</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>100%</b> improvements of at least 2 grades</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>96%</b> improvements of at least 3 grades</span></div>
                </div>

                <router-link to="/tutors/mr-lai" class="mr-2 lg:mr-4 px-5 py-1 inline-block border rounded-lg hover:bg-white hover:text-gray-800 transition ease-linear mt-10">Learn More About Me</router-link>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-6 lg:gap-20 mt-32 mx-auto mb-12 lg:mb-0 relative lg:rounded-lg overflow-hidden">
            <img src="\assets\mrChua.jpg" alt="Mr Chua picture" class="tutorPic col-span-4 lg:col-span-2 w-full border-2 shadow-sm rounded-lg" />
            <div class="col-span-4 lg:col-span-2 mt-10">
                <p class="font-bold text-3xl">Ding En / Chester (Mr. Chua)</p>
                <p class="italic mt-4 text-lg">Co-Founder, Head of Coaching</p>
                <p class="italic text-lg">Principal Tutor (PSLE/N/O Levels)</p>

                <div class="mt-8 lg:w-2/3">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Background</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>Bachelor of Business Mgmt (Cum Laude), SMU</div>
                    <div class="pl-10">Major in Organizational Behaviour & HR</div>
                    <div class="flex text-left items-center mt-6"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>MOE-Registered External Trainer of 200+ Hours in Cyber Wellness, Leadership, and Intervention (Gallup StrengthsFinder)</div>               
                </div>
                <router-link to="/tutors/mr-chua" class="mr-2 lg:mr-4 px-5 py-1 inline-block border rounded-lg hover:bg-white hover:text-gray-800 transition ease-linear mt-10">Learn More About Me</router-link>
            </div>
        </div>
    </div>

    <div class="bg-gray-50 pt-14 pb-14 lg:px-40 px-6 text-center">
        <div class="text-center mb-8">
            <h3 class="font-extrabold tracking-wide text-3xl lg:text-4xl uppercase lg:w-1/2 mx-auto">WE HELP STUDENTS ENTER THEIR DREAM SCHOOLS TOO</h3>
        </div>
        <p class="text-center lg:w-2/3 mx-auto text-lg mb-14">Since 2020, we have achieved an 85% DSA or EAE rate for Primary and Secondary students to pursue their dreams!</p>

        <div class="grid grid-cols-3 gap-12 mx-auto mb-12 lg:mb-0 carousel relative lg:rounded-lg overflow-hidden">
            <img src="/assets/testimonials/coaching1.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/coaching2.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
            <img src="/assets/testimonials/coaching3.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
        </div>

        <router-link to="/contact" class="px-5 py-1 inline-block border rounded-lg btn transition ease-linear mt-10">Find Out More!</router-link>
    </div>

    <div id="testimonials" class="overflow-x-hidden relative bg-gray-800 text-white pt-14 pb-14 lg:px-40 px-6">
        <h2 class="text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase mb-8">Hear More from our Saved-From-Math Students!</h2>
        <button @click="slide(-1)" class="transition ease-linear hover:text-gray-500 lg:absolute lg:left-32 lg:top-1/2"><i class="text-2xl lg:text-3xl fas fa-angle-left mt-3" /></button>
        <button @click="slide(1)" class="float-right transition ease-linear hover:text-gray-500 lg:absolute lg:right-32 lg:top-1/2"><i class="text-2xl lg:text-3xl fas fa-angle-right mt-3" /></button>
        <!-- Testimonial carousel -->
        <div class="py-8 xl:mx-auto">
            <div :class="currentSlide == 1 ? '' : 'hidden'" id="slide1" class="lg:w-2/3 mx-auto hover:shadow-lg transition ease-linear bg-white text-gray-800 shadow py-6 lg:px-8 px-4">
                <div class="lg:flex items-center mr-auto">
                    <img src="/assets/testimonials/justinMoh.webp" alt="Justin Moh photo" class="border-2 border-gray-800 rounded-full">
                    <div class="mt-4 lg:mt-0 lg:ml-8">
                        <h3 class="font-bold text-lg">Justin Moh</h3>
                        <h4 class="mb-2">Hua Yi Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Patient and effective, Mr. Lai goes the extra mile and helps me in his own time..."</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">When starting, my results weren’t very good with me being almost clueless with AMath. But, Mr. Lai was patient with me and taught me the topics that I was uncertain about. He uses the iPad effectively, showing the explanations behind certain steps while also helping visualise questions in a simpler way. Even after lessons, if I had any questions or doubts, Mr. Lai goes the extra mile and help me in his own time. The questions he gives as homework are sometimes on the tough side, but he always patiently goes through those that I’m unfamiliar with in tuition class.</div>
            </div>
            <div :class="currentSlide == 2 ? '' : 'hidden'" id="slide2" class="lg:w-2/3 mx-auto hover:shadow-lg transition ease-linear bg-white text-gray-800 shadow py-6 lg:px-8 px-4">
                <div class="lg:flex items-center mr-auto">
                    <img src="/assets/testimonials/aymericLee.webp" alt="Aymeric Lee photo" class="border-2 border-gray-800 rounded-full">
                    <div class="mt-4 lg:mt-0 lg:ml-8">
                        <h3 class="font-bold text-lg">Aymeric Lee</h3>
                        <h4 class="mb-2">Serangoon Gardens Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Mr. Lai's lessons are one of a kind to me!"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">He is one of the rare few teachers that teaches with an iPad. His teaching skills are also very good as he would explain it in the most efficient way possible just to get his students to an A1. Whenever I needed help, he was always responsive even by text, and explains his steps clearly to make sure I understood the concepts required. The pace of the lessons is quite fast but it is always adjustable as long as you inform him. He slows down and pays attention to the weaker chapters I have before moving on to the next chapter. Overall, he is a very understanding and very kind teacher who always go the extra mile to help his students.</div>
            </div>
            <div :class="currentSlide == 3 ? '' : 'hidden'" id="slide3" class="lg:w-2/3 mx-auto hover:shadow-lg transition ease-linear bg-white text-gray-800 shadow py-6 lg:px-8 px-4">
                <div class="lg:flex items-center mr-auto">
                    <img src="/assets/testimonials/kunHong.webp" alt="Kun Hong photo" class="border-2 border-gray-800 rounded-full">
                    <div class="mt-4 lg:mt-0 lg:ml-8">
                        <h3 class="font-bold text-lg">Ea Kun Hong</h3>
                        <h4 class="mb-2">Yishun Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Only tutor that motivates me to do and learn more"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr Lai is a teacher that helps me a lot by improving my understanding in mathematics. The pace of the lessons is often just right for me. Coupled with his patience, he explains the method on solving the questions effectively and in the simplest way. His methods of using an iPad in his teaching saves a lot of time while allowing me to visualize mathematical problems easily. During the June Holidays, he goes the extra mile to also check on my homework and ensure my answers are correct. He motivates me to do more practices in order to achieve A1 for the subject.</div>
            </div>
            <div :class="currentSlide == 4 ? '' : 'hidden'" id="slide4" class="lg:w-2/3 mx-auto hover:shadow-lg transition ease-linear bg-white text-gray-800 shadow py-6 lg:px-8 px-4">
                <div class="lg:flex items-center mr-auto">
                    <img src="/assets/testimonials/lukeTan.webp" alt="Luke Tan photo" class="border-2 border-gray-800 rounded-full">
                    <div class="mt-4 lg:mt-0 lg:ml-8">
                        <h3 class="font-bold text-lg">Luke Tan</h3>
                        <h4 class="mb-2">Tanjong Katong Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Always making sure that we can effectively grasp the learning points..."</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr. Lai is a wonderful teacher! He is very patient in explaining the points to his students using his iPad, making sure that we can effectively grasp the learning points. Punctual and well-prepared, his lessons are very efficient and often designed in a way that allows students to learn and practice as much as possible. This has even allowed students such as me to learn faster than the school curriculum. Last but not least, he always helps me beyond class time to ensure that my questions are answered! Thank you, Mr. Lai!</div>
            </div>
            <div :class="currentSlide == 5 ? '' : 'hidden'" id="slide5" class="lg:w-2/3 mx-auto hover:shadow-lg transition ease-linear bg-white text-gray-800 shadow py-6 lg:px-8 px-4">
                <div class="lg:flex items-center mr-auto">
                    <img src="/assets/testimonials/pranav.webp" alt="Pranav photo" class="border-2 border-gray-800 rounded-full">
                    <div class="mt-4 lg:mt-0 lg:ml-8">
                        <h3 class="font-bold text-lg">Pranav</h3>
                        <h4 class="mb-2">Millenia Institute</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Different approach to all the other teachers that I've seen"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr Lai started to teach me in J1 for math. Initially, I lack the foundation for Mathematics (F9 in AMath and C5 for EMath during my O levels). Hence, I was lagging behind from the very beginning in my JC journey. However, thanks to Mr. Lai, it is of great joy that I am now consistently getting As for H1 math.<br><br>He always teaches from his personal notes that are succinct and easy to understand. Changing the pace depending on my learning speed often ensures that my learning takes priority by explaining every small detail to fully understand the chapter. He reinforces my learning and makes sure all my good and weak topics are covered through an abundance of questions with all levels of difficulty. Extra classes and examination papers are often also catered and given to me to make sure that I am ready for my examinations – he even answers questions outside of class time through telephone messages to ensure that I am ready for my papers. A hardworking and highly motivated teacher – it motivates me to work hard for math as well. I highly recommend all to take his classes who are struggling in math!</div>
            </div>
        </div>

        <div class="gap-4 flex flex-col lg:flex-row justify-center text-center my-auto mt-6 lg:mt-10">
            <router-link to="/faq" class="px-5 py-1 inline-block border rounded-lg btn transition ease-linear bg-transparent">FAQ</router-link>
            <router-link to="/testimonials" class="px-5 py-1 inline-block border rounded-lg btn transition ease-linear bg-transparent">See What Others Say</router-link>
            <router-link to="/contact" class="px-5 py-1 inline-block border rounded-lg btn transition ease-linear bg-transparent">Sign Me Up Now!</router-link>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
// import Action from "../components/Action";
// import Lightbox from "../components/Lightbox";

export default {
    name: "Home",
    components: {
        // Action,
        // Lightbox
    },
    data() {
        return {
            currentSlide: 1,
            popup: [
                {
                    type: "image",
                    src: "/assets/popup.png"
                }
            ]
        }
    },
    mounted() {
        gsap.to("#landingBtn", { opacity: 1, yPercent: 0, duration: 0.5 });
        setInterval(() => {
            this.slide(1);
        }, 10000)
        
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
        // Auto shown on render
        // this.$refs.popup.toggleLightbox(true)
    },
    methods: {
        revealProgramme(name) {
            gsap.to(`#${name} p`, { opacity: 1, translateY: "0%", duration: 0.2 })
        },     
        hideProgramme(name) {
            gsap.to(`#${name} p`, { opacity: 0, translateY: "-10px", duration: 0.3 })
        },
        slide(dir) {
            if (this.currentSlide < 5 && dir == 1) {
                this.currentSlide++;
                gsap.fromTo(`#slide${this.currentSlide}`, { xPercent: -50, opacity: 0 }, { opacity: 1, xPercent: 0 , duration: 0.2 })
            } else if (this.currentSlide == 5 && dir == 1) {
                this.currentSlide = 1;
                gsap.fromTo(`#slide1`, { xPercent: -50, opacity: 0 }, { opacity: 1, xPercent: 0 , duration: 0.2 })
            } else if (this.currentSlide > 1 && dir == -1) {
                this.currentSlide--;
                gsap.fromTo(`#slide${this.currentSlide}`, { xPercent: 50, opacity: 0 }, { opacity: 1, xPercent: 0 , duration: 0.2 })
            } else {
                this.currentSlide = 5;
                gsap.fromTo(`#slide5`, { xPercent: 50, opacity: 0 }, { opacity: 1, xPercent: 0 , duration: 0.2 })
            }
        }
    }
}
</script>

<style>
.bg-gray-90 {
    background: rgba(31, 41, 55, 0.90);
}

.bg-gray-60 {
    background: rgba(31, 41, 55, 0.60);
}
</style>

<style scoped>
#landingTitle {
    background: rgb(71,85,105);
    background: linear-gradient(100deg, rgba(71,85,105,1) 0%, rgba(31,41,55,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#headerDiv {
    height: 75vh;
    background: url("/assets/picture3.jpg");
    background-size: cover;
    background-position: 50% 10%;
}

.bg-white-70 {
    background: rgba(255, 255, 255, 0.7);
}

@media only screen and (min-width : 320px) {
    #headerDiv {
        margin-top: calc(50px + 0.5rem);
    }
    .tutorPic {
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: 50% 0%;
    }
}

@media only screen and (min-width : 1025px) {
    #headerDiv {
        margin-top: calc(70px + 0.5rem);
    }
    .tutorPic {
        height: 600px;
        object-fit: cover;
        object-position: 50% 0%;
    }
}

#testimonials img {
    height: 160px;
    width: 160px;
}
</style>