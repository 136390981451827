<template>
  <Navbar v-if="this.$route.path !== '/sign-up-now-2023'" />
  <router-view />
  <Footer v-if="this.$route.path !== '/sign-up-now-2023'" />
</template>

<script>
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}


* {
  font-family: 'Lato', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-x: hidden;
  color: #1f2937;
}

::selection {
  background: #4B5563;
  color: #F9FAFB;
}
</style>
