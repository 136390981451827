<template>
    <div :class="colors === 'inverse' ? 'bg-gray-50' : 'bg-gray-800 text-white'" class="lg:flex py-10 lg:py-14 text-lg justify-around lg:px-40">
        
        <h6 class="font-bold text-center lg:text-left uppercase text-2xl tracking-wide">Get in touch now!</h6>
        <div class="text-center mt-6 lg:mt-0">
            <router-link to="/contact" class="mr-2 lg:mr-4 px-5 py-1 inline-block" :class="colors==='inverse' ? 'btn' : 'border rounded-lg hover:bg-white hover:text-gray-800 transition ease-linear'">Contact Us</router-link>
            <router-link to="/programmes/2024-schedule" class="lg:ml-4 px-5 py-1 inline-block" :class="colors==='inverse' ? 'btn' : 'border rounded-lg hover:bg-white hover:text-gray-800 transition ease-linear'">2024 Schedule</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Action",
    props: {
        colors: String
    }
}
</script>

<style>
</style>