<template>
    <div class="bg-white w-full text-gray-800 grid md:grid-cols-4 lg:pt-10 py-5 lg:px-16 xl:px-32 px-6">
        <div class="col-span-4 xl:mt-0 mt-8 order-2 xl:order-1 xl:col-span-1">
            <h5 class="font-bold text-xl mb-2 uppercase tracking-wider">Find Us</h5>
            <div class="flex pb-2">
                <i class="fa-solid fa-location-dot text-2xl my-auto"></i>
                <div>
                  <p class="ml-4 text-gray-800 font-semibold">Math Vigilante Tuition - Katong</p>
                  <p class="ml-4 text-gray-800">865 Mountbatten Road, #02-77<br />Katong Shopping Centre, Katong, Singapore 437844</p>
                </div>
            </div>
            <div class="flex pb-2">
                <i class="fa-solid fa-location-dot text-2xl my-auto"></i>
                <div>
                  <p class="ml-4 text-gray-800 font-semibold">Math Vigilante Tuition - Hougang</p>
                  <p class="ml-4 text-gray-800">806 Hougang Central, #03-144<br />Hougang, Singapore 530806</p>
                </div>
            </div>
            <div class="flex">
                <i class="fa-solid fa-clock text-2xl my-auto"></i>
                <div>
                  <p class="ml-4 text-gray-800 font-semibold">Operating Hours</p>
                  <p class="ml-4 text-gray-800">Monday to Friday: 3:00PM - 9:30PM</p>
                  <p class="ml-4 text-gray-800">Saturday and Sunday: 9:00AM - 6:00PM</p>
                </div>
            </div>
        </div>

        <div class="col-span-4 md:col-span-1 order-1 xl:order-2 xl:col-span-1 xl:mt-0 mt-6 xl:ml-16">
            <h5 class="font-bold text-xl mb-2 uppercase tracking-wider">Contact Us</h5>
            <div class="flex">
                <i class="fa-solid fa-phone text-2xl my-auto"></i>
                <div class="ml-4">
                    <a href="https://wa.me/+6580426428" rel="noopener" target="_blank" class="hover:underline align-middle">8042 6428</a>
                </div>
            </div>
            <div class="flex mt-2">
                <i class="fa-solid fa-envelope text-2xl"></i>
                <a href="mailto:hello@mathvigilante.com" class="ml-4 my-auto hover:underline">hello@mathvigilante.com</a>
            </div>
            <h5 class="font-bold text-xl mb-2 uppercase tracking-wider mt-8">Follow Us</h5>
            <a class="mr-2" aria-label="Facebook" href="https://www.facebook.com/MathVigilanteTuition" rel="noopener" target="_blank"><i class="fa-brands text-3xl fa-facebook-square"></i></a>
            <a class="ml-1" aria-label="Instagram" href="https://www.instagram.com/mathvigilante_" rel="noopener" target="_blank"><i class="fa-brands text-3xl fa-instagram"></i></a>
        </div>

        <div id="footerLinks" class="col-span-4 md:col-span-1 order-2 xl:order-3 xl:col-span-1 xl:mt-0 mt-8 xl:ml-16">
            <h5 class="font-bold inline-block text-xl mb-2 uppercase tracking-wider">Links</h5><br>
            <router-link to="/" class="inline-block hover:underline">Home</router-link><br>
            <router-link to="/tutors/mr-lai" class="mt-1 inline-block hover:underline">Mr. Lai (Principal Tutor & Scholar)</router-link><br>
            <router-link to="/tutors/mr-chua" class="mt-1 inline-block hover:underline">Mr. Chua (Principal Coach & Developer)</router-link><br>
            <router-link to="/testimonials" class="mt-1 inline-block hover:underline">Student Endorsements</router-link><br>
            <router-link to="/results" class="mt-1 inline-block hover:underline">Results & Achievements</router-link><br>
            <router-link to="/faq" class="mt-1 inline-block hover:underline">FAQ</router-link><br>
            <router-link to="/contact" class="mt-1 inline-block hover:underline">Enroll Now!</router-link><br>
        </div>

        <div id="footerProgrammes" class="col-span-4 md:col-span-1 order-2 xl:order-4 xl:col-span-1 xl:mt-0 mt-8 xl:ml-16">
            <h5 class="font-bold inline-block text-xl mb-2 uppercase tracking-wider">Programmes & Rates</h5><br>
            <router-link to="/programmes/lower-pri" class="inline-block hover:underline">Primary 1-3</router-link><br>
            <router-link to="/programmes/upper-pri" class="inline-block hover:underline">Primary 4-6</router-link><br>
            <router-link to="/programmes/sec-express" class="mt-1 inline-block hover:underline">Sec 1/2 Express (G3)</router-link><br>
            <router-link to="/programmes/sec-express" class="mt-1 inline-block hover:underline">Sec 3/4 Express (G3)</router-link><br>
            <router-link to="/programmes/sec-na-nt" class="mt-1 inline-block hover:underline">Sec 1/2 NA (G2) / NT (G1)</router-link><br>
            <router-link to="/programmes/sec-na-nt" class="mt-1 inline-block hover:underline">Sec 3/4/5 NA (G2) / NT (G1)</router-link><br>
            <router-link to="/programmes/junior-college" class="mt-1 inline-block hover:underline">Junior College H1/H2</router-link><br>
            <router-link to="/programmes/private-candidates" class="mt-1 inline-block hover:underline">Others</router-link><br>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer" 
}
</script>

<style scoped>
</style>