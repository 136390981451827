<template>
    <div id="contactContainer" class="lg:py-16">
        <div class="bg-gray-90 lg:rounded-lg shadow text-white py-20 px-6 lg:w-4/5 xl:w-2/3 mx-auto">
            <div class="text-center mb-14">
                <h4 class="font-extrabold tracking-wide text-3xl lg:text-4xl uppercase">Still unsure? <span id="worryText" class="transform -translate-y-full opacity-0 inline-block">Not to worry!</span></h4>
                <p class="text-gray-200 lg:text-xl mt-6">We have trial classes that allow your child to access the following:</p>
                <ul class="mt-4">
                    <li class="">A <strong>personalised diagnosis</strong> regarding your child’s Math standard</li>
                    <li class="mt-1">First-hand experience in <strong>saving themselves from Mathematics</strong></li>
                    <li class="mt-1"><strong>Learn in a safe and fun environment</strong> with our young tutors</li>
                </ul>
            </div>
            <ContactForm class="lg:w-2/3" />
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
import ContactForm from "../components/ContactForm";

export default {
    name: "Contact",
    components: {
        ContactForm
    },
    mounted() {
        gsap.to("#worryText", { translateY: "0%", opacity: 1, duration: 1 });
        document.querySelector('meta[name="description"]').setAttribute("content", 'Register Today To Improve Now. Limited Slots Available.');
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #contactContainer {
        margin-top: calc(50px + 0.5rem);
    }
}

@media only screen and (min-width : 1025px) {
    #contactContainer {
        margin-top: calc(70px + 0.5rem);
    }
}

#contactContainer {
    background: url("/assets/picture2.jpg");
    background-size: cover;
    background-position: top;
}
</style>