<template>
    <div ref="container" tabindex="0" @keydown.esc="shown = !shown" v-if="shown" id="lightsOut" class="h-screen w-screen fixed top-0 left-0 flex justify-center items-center">
        <div id="box">
            <img v-if="media[index].type==='image'" class="rounded-lg shadow" :src="media[index].src" alt="Lightbox Image" />
        </div>
        
        <button id="left" v-if="this.media.length > 1" class="absolute hover:text-gray-500 text-gray-200 transition duration-75 ease-linear top-1/2 left-4 lg:left-12 text-2xl" @click="moveIndex(-1)"><i class="fa-solid fa-angle-left"></i></button>
        <button id="right" v-if="this.media.length > 1" class="absolute hover:text-gray-500 text-gray-200 transition duration-75 ease-linear top-1/2 right-4 lg:right-12 text-2xl" @click="moveIndex(1)"><i class="fa-solid fa-angle-right"></i></button>
        <button id="close" class="absolute hover:text-gray-500 text-gray-200 transition duration-75 ease-linear top-6 right-6 lg:right-14 text-3xl" @click="shown = !shown"><i class="fa-solid fa-xmark"></i></button>
    </div>
</template>

<script>
export default {
    name: "Lightbox",
    props: {
        media: Array
    },
    components: {
    },
    methods: {
        toggleLightbox(state) {
            this.shown = state
        }
    },
    data() {
        return {
            shown: false,
            index: 0
        }
    },
    watch: {
        shown(value) {
            if (value) {
                setTimeout(() => {
                    this.$refs.container.focus()
                }, 50)
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (min-device-width: 320px) {
    #box img {
        width: 80vw;
        z-index: 2000;
    }
}

@media only screen and (min-device-width: 992px) {
    #box img {
        height: 80vh;
        width: auto;
        z-index: 2000;
    }
}

#box img {
    object-fit: contain;
}

#lightsOut {
    background: rgba(0, 0, 0, 0.80);
    z-index: 99;
}
</style>