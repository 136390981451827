<template>
    <div id="container" class="">
        <div class="headerContainer">
            <p class="header flex justify-center items-center text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase w-full">Frequently Asked Questions</p>
        </div>
        
        <div class="flex flex-col gap-8 bg-gray-800 lg:px-40 px-6 py-8 lg:py-14">
            <Question question="I understand you’re the first N Level Math Focus Tuition centre. Why did this start and how do you teach them?" answer="Over more than 8 years of tutoring, we saw an increase in number of students struggling with math and taking N level Mathematics. Finding it a huge concern for us, we want to help these kids and know that Math is manageable with the right effort and guidance.<br /><br />For these students, we have a Math Coaching program that is designed to be one-to-one, to provide the students necessary tools to perform in the Math topics. A typical session will look like this: (1) Introducing new concept with an iPad, (2) Crafting their own notes to suit their learning style, (3) Foundation building through basic practices, (4) Independent learning with guidance and support." />
            <Question question="My fundamentals are weak, and I don’t feel confident or comfortable joining the usual 2-hour classes. What should I do?" answer="Not to worry! We have 1-to-1 syllabus bridging sessions for weaker students. You can opt not to go through with the usual classes with the rest. Instead, we recommend these students to join our coaching programs where they are subjected to foundation building.<br /><br />Students can also opt to attend usual cohort classes on top of additional bridging sessions to not only ensure their foundation gets built up, but they also do not lose out to their peers with the ongoing topics taught today.<br /><br />Though at an additional cost, this is temporary, and students can ‘graduate’ from the bridging sessions and attend their regular usual classes soon after they are saved from Mathematics! 😉" />
            <Question question="Topics taught in schools might be different. How does Math Vigilante address this concern?" answer="Our syllabus is designed as part of MOE’s syllabus and order. In the case where there are differences due to the child’s school’s directive, we will provide supplementary opportunities in class when examinations are nearing.<br /><br />As our class size is small, supplementary opportunities are aplenty. While other students practice for their examinations, our tutors will personally coach any additional topics if needed." />
            <Question question="Can I consult the teacher outside of class?" answer="Yes! Beyond our classes, we also provide 24/7 online consultations on our own time to help students with homework and quick conceptual corrections to assist their learning at home. Extra bridging classes can be arranged nearing examination season too." />

        </div>
    </div>
</template>

<script>
import Question from "../components/Question.vue"

export default {
    name: "FAQ",
    components: {
        Question
    },
    mounted() {
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #container {
        margin-top: calc(50px + 0.5rem);
    }
}

@media only screen and (min-width : 1024px) {
    #container {
        margin-top: calc(70px + 0.5rem);
    }
}

.headerContainer {
    background: url("/assets/psle.jpg");
    background-size: cover;
    background-position: 50% 35%;
}

.header {
    background: rgba(255, 255, 255, 0.7);
    min-height: 30vh;
}
</style>