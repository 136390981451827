<template>
    <div id="results" class="grid grid-cols-3 w-3/4 mx-auto gap-5 pt-14 pb-10">
        <h2 class="col-span-3 text-center font-extrabold tracking-wide text-3xl lg:text-4xl uppercase mb-2">Great results from our students</h2>
        <h4 class="col-span-3 text-center tracking-wide text-lg text-gray-600 mb-8">Showcasing our proven track record with students.</h4>

        <img @click="this.$refs[`lightbox${n}`].toggleLightbox(true)" v-for="n in 24" :key="n" :src="`/assets/testimonials/testimonial${n}.png`" alt="Testimonial picture" class="col-span-3 lg:col-span-1 cursor-pointer w-full border-2 shadow-sm rounded-lg hover:scale-105 transform transition ease-in-out hover:z-30"/>
    </div>

    <Lightbox v-for="n in 12" :key="n" :ref="`lightbox${n}`" :media="[{type:'image', src: `/assets/testimonials/testimonial${n}.png`}]" />
</template>

<style scoped>
@media only screen and (min-width : 320px) {
    #results {
        margin-top: calc(50px + 0.5rem);
    }
}

@media only screen and (min-width : 1024px) {
    #results {
        margin-top: calc(70px + 0.5rem);
    }
}
</style>

<script>
import Lightbox from "../components/Lightbox"

export default {
    name: "Results",
    data() {
        return {
            currentTestimonial: 1
        }
    },
    components: {
        Lightbox
    },
    mounted() {
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
    }
}
</script>