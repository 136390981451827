import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import Contact from "../views/Contact";
import Program from "../views/Program";
import Tutors from "../views/Tutors";
import Resources from "../views/Resources";
import Testimonials from "../views/Testimonials";
import Results from "../views/Results";
import NotFound from "../views/NotFound";
import FAQ from "../views/FAQ"
import SignUpNow from "../views/SignUpNow"

const routes = [
    { path: "/", component: Home },
    { path: "/contact", component: Contact },
    { path: "/programmes/:program", component: Program, props: true },
    { path: "/tutors/:tutor", component: Tutors, props: true },
    { path: "/testimonials", component: Testimonials },
    { path: "/results", component: Results },
    { path: "/resources", component: Resources },
    { path: "/faq", component: FAQ },
    { path: "/:pathMatch(.*)*", component: NotFound },
    { path: "/sign-up-now-2023", component: SignUpNow }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
        return { 
          top: 0,
          behavior: 'smooth'
        }
    }
  }
});

export default router;