<template>
    <div>
        <div :class="display ? 'bg-gray-200 rounded-t-lg' : 'bg-gray-50 rounded-lg'" class="shadow font-semibold lg:text-xl px-6 py-2.5 lg:px-8 lg:py-4 hover:bg-gray-200 transition cursor-pointer flex gap-10 justify-between items-center" @click="display = !display">{{question}}<i :class="display ? 'rotate-180 transform' : ''" class="transition duration-150 ease-in-out fa-solid fa-chevron-down text-sm" /></div>
        <div class="bg-gray-50 transition px-8 pt-4 pb-6 ease-in-out lg:text-lg" :class="display ? 'rounded-b-lg' : 'hidden'" v-html="answer"></div>
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        answer: String
    },
    data() {
        return {
            display: false
        }
    }
}
</script>

<style scoped>

</style>