<template>
    <div v-if="tutor === 'mr-lai'" id="tutorContainer">
        <div class="bg-gray-800 text-white xl:px-32 lg:px-16 py-20 px-6 text-center">
            <div class="wideTutorDesc mx-auto">
                <p class="font-extrabold text-2xl">His Fellow Classmates Called him the “Math Genius”</p>
                <p class="text-lg mt-4 lg:w-2/3 mx-auto">His love for Mathematics started back in Primary school when he achieved his first ever “Top in Mathematics” Award in Primary 3, continuing to achieve Distinctions throughout his student life while helping many classmates and juniors to do the same; he has now <b>transformed into a full-fledge tutor to save even more students for Mathematics.</b></p>
            </div>
        </div>

        <div class="bg-gray-50 grid grid-cols-2 lg:gap-32 xl:px-32 lg:px-16 py-20 px-6">
            <div class="col-span-2 lg:col-span-1">
                <img src="/assets/mrLai.jpg" alt="Mr Lai photo" class="rounded-lg ml-auto tutorPhoto" />
            </div>
            <div class="tutorDesc mt-10 col-span-2 lg:col-span-1">
                <h6 class="text-3xl font-bold">Zhi Khong / Zac (Mr. Lai)</h6>
                <p class="mt-2 leading-relaxed text-lg italic">Co-Founder, Head of Curriculum</p>
                <p class="leading-relaxed text-lg italic">Principal Tutor (O/A Levels)</p>

                <div id="laiBg">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Background</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>NUS Merit Scholar (Top 1%), Double Degree</div>
                    <div class="pl-10">Bachelor of Business (Finance), NUS</div>
                    <div class="pl-10 mt-1">Bachelor of Arts (Economics), NUS</div>   
                    <div class="flex text-left mt-6"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>NTUC Income Prize (Top Student in FIN3703: Financial Markets)</div>
                </div>

                <div id="laiRecord" class="mt-6">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Track Record</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>6+</b> years of teaching (> 30 students)</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>86%</b> distinction across all students</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>100%</b> improvements of at least 2 grades</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>96%</b> improvements of at least 3 grades</span></div>
                </div>
            </div>
        </div>

        <!-- <div id="record" class="bg-gray-800 text-white grid grid-cols-2 gap-32 xl:px-32 lg:px-16 py-20 px-6">
            <div class="tutorDesc mt-10 col-span-2 lg:col-span-1 ml-auto">
                <h5 class="font-extrabold text-3xl">Thus, He Embarked on A Journey to Help the Next Generation of Students</h5>
                <p class="mt-4 leading-relaxed">Since graduating from Victoria Junior College, he has clocked more than 37,000 hours in Mathematics tutoring over the past 6 years.</p>
                <div>
                    <h6 class="mt-6 mb-2 font-bold text-lg">Track Record</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>86%</b> distinction across all students</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>100%</b> improvements of at least 2 grades</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>93%</b> improvements of at least 3 grades</span></div>
                </div>
                <router-link to="/tutors/mr-lai#success" class="mt-12 px-5 py-1 inline-block btn-inverse">See Successes by His Students</router-link>
            </div>
            <div class="col-span-2 lg:col-span-1">
                <img src="/assets/lai/pic1.jpg" alt="Mr Lai photo" class="rounded-lg mr-auto tutorPhoto object-contain object-top" />
            </div>
        </div> -->

        <div id="success" class="bg-gray-800 text-white xl:px-32 lg:px-16 py-20 px-6">
            <div class="wideTutorDesc mx-auto lg:w-2/3 text-center pb-20">
                <h5 class="font-extrabold text-3xl">Practice and Consistency is as Important as Building Trust with The Student</h5>
                <p class="mt-4 leading-relaxed">Having reached out to so many students today, I’ve built lots of trust with my students; transforming them into independent learners that are willing to work hard and perform. Saving many students from Mathematics, see what they say!</p>
            </div>
            <div class="flex flex-wrap lg:gap-32 justify-center">
                <div class="">
                    <img src="/assets/lai/pic1.jpg" alt="Mr Lai photo" class="rounded-lg ml-auto tutorPhotoWidth object-contain object-top" />
                </div>
                <div class="tutorDesc mt-4">
                    <h6 class="font-extrabold text-3xl">Consistent A1 for AMath & EMath ever since Joining</h6>
                    <p class="mt-4 leading-relaxed">"His teaching skills are also very good as he would explain it in the most efficient way possible just to get his students to an A1. Whenever I needed help, he was always responsive even by text, and explained his steps clearly to make sure I understood the concepts required. Overall, he is a very understanding and very kind teacher who always goes the extra mile to help his students."</p>
                    <p class="mt-4 leading-relaxed">Aymeric Lee, Sec 3 Double Math<br/>Serangoon Secondary (joined since Sec 1)</p>
                </div>
            </div>
            <!-- <div class="flex flex-wrap gap-32 justify-center xl:-mt-32">
                <div class="order-1 lg:order-2">
                    <img src="/assets/lai/pic1.jpg" alt="Mr Lai photo" class="rounded-lg ml-auto tutorPhoto object-contain object-top" />
                </div>
                <div class="tutorDesc mt-10 order-2 lg:order-1">
                    <h6 class="font-extrabold text-3xl">Consistent A1 for AMath & EMath ever since Joining</h6>
                    <p class="mt-4 leading-relaxed">"His teaching skills are also very good as he would explain it in the most efficient way possible just to get his students to an A1. Whenever I needed help, he was always responsive even by text, and explained his steps clearly to make sure I understood the concepts required. Overall, he is a very understanding and very kind teacher who always goes the extra mile to help his students."</p>
                    <p class="mt-4 leading-relaxed">Aymeric Lee, Sec 3 Double Math<br/>Serangoon Secondary (joined since Sec 1)</p>
                </div>
            </div>
            <div class="flex flex-wrap lg:gap-32 justify-center xl:-mt-32">
                <div class="">
                    <img src="/assets/lai/pic1.jpg" alt="Mr Lai photo" class="rounded-lg ml-auto tutorPhoto object-contain object-top" />
                </div>
                <div class="tutorDesc mt-10">
                    <h6 class="font-extrabold text-3xl">Consistent A1 for AMath & EMath ever since Joining</h6>
                    <p class="mt-4 leading-relaxed">"His teaching skills are also very good as he would explain it in the most efficient way possible just to get his students to an A1. Whenever I needed help, he was always responsive even by text, and explained his steps clearly to make sure I understood the concepts required. Overall, he is a very understanding and very kind teacher who always goes the extra mile to help his students."</p>
                    <p class="mt-4 leading-relaxed">Aymeric Lee, Sec 3 Double Math<br/>Serangoon Secondary (joined since Sec 1)</p>
                </div>
            </div>
             -->
            <div class="flex justify-center lg:mt-12">
                <router-link to="/tutors/mr-lai#final" class="mt-12 px-5 py-1 inline-block btn-inverse text-center">Final Message</router-link>
            </div>
        </div>

        <!-- <div id="how" class="bg-gray-800 text-white grid grid-cols-2 gap-32 xl:px-32 lg:px-16 py-20 px-6">
            <div class="col-span-2 lg:col-span-1">
                <img v-if="tutor == 'mr-lai'" src="/assets/mrLai.jpg" alt="Mr Lai photo" class="rounded-lg ml-auto tutorPhoto" />
                <img v-if="tutor == 'mr-chua'" src="https://images.unsplash.com/photo-1567168539593-59673ababaae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="Mr Chua photo" class="rounded-lg ml-auto tutorPhoto" />
            </div>
            <div class="tutorDesc mt-10 col-span-2 lg:col-span-1">
                <h5 class="font-extrabold text-3xl">Curriculum Design</h5>
                <p class="mt-4 leading-relaxed">XXXX</p>
                <router-link to="/tutors/mr-chua#final" class="mt-12 px-5 py-1 inline-block btn-inverse">Final Message</router-link>
            </div>
        </div> -->

        <div id="final" class="bg-gray-50 xl:px-32 lg:px-16 py-20 px-6 text-center">
            <div class="wideTutorDesc mx-auto lg:w-2/3">
                <h5 class="font-extrabold text-3xl">Humble Beginnings, to Rescue students from Mathematics</h5>
                <p class="mt-4 leading-relaxed">Over seven years of regular tutoring and many distinctions for students later, it is my aspiration to pursue this passion and enrich every student’s interaction with Mathematics. Alongside Mr. Chua, we will serve as mentors for your child, ensuring that they navigate examinations effectively.</p>
                <p class="mt-4 leading-relaxed text-xl">Inviting you to achieve your distinction with me!</p>

                <div class="flex gap-4 lg:gap-8 justify-center">
                    <router-link to="/contact" class="mt-12 px-5 py-1 inline-block btn">Enroll Now</router-link>
                    <a href="https://wa.me/+6580426428" class="mt-12 px-5 py-1 inline-block btn">My Whatsapp</a>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="tutor === 'mr-chua'" id="tutorContainer">
        <div class="bg-gray-800 text-white xl:px-32 lg:px-16 py-20 px-6 text-center">
            <div class="wideTutorDesc mx-auto">
                <p class="font-extrabold text-2xl">I support and guide students that struggle with Math across all levels.</p>
                <p class="text-lg mt-4 lg:w-2/3 mx-auto">Tutoring and working with students for past 8 years, I have learnt and understood how younger and weaker students learn and have identified the support they need.<br /><br />Join me to save your child from Mathematics!</p>
            </div>
        </div>

        <div class="bg-gray-50 grid grid-cols-2 lg:gap-32 xl:px-32 lg:px-16 py-20 px-6">
            <div class="col-span-2 lg:col-span-1">
                <img src="/assets/mrChua.jpg" alt="Mr Chua photo" class="rounded-lg ml-auto tutorPhoto" />
            </div>
            <div class="tutorDesc mt-10 col-span-2 lg:col-span-1">
                <h6 class="text-3xl font-bold">Ding En / Chester (Mr. Chua)</h6>
                <p class="mt-2 leading-relaxed text-lg italic">Co-Founder, Head of Coaching</p>
                <p class="leading-relaxed text-lg italic">Principal Tutor (PSLE/N/O Levels)</p>

                <div id="chuaBg">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Background</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>Bachelor of Business Mgmt (Cum Laude), SMU</div>
                    <div class="pl-10">Major in Organizational Behaviour & HR</div>
                    <div class="flex text-left mt-6"><i class="align-middle fa-solid fa-certificate text-2xl mr-3"></i>MOE- Registered External Trainer of 200+ Hours in Cyber Wellness, Leadership, and Intervention (Gallup StrengthsFinder)</div>
                </div>

                <div id="chuaRecord" class="mt-6">
                    <h6 class="mt-6 mb-2 font-bold text-lg">Track Record</h6>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>6+</b> years of teaching (>25 students)</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>82%</b> distinction across all students</span></div>
                    <div class="flex text-left items-center"><i class="align-middle fa-solid fa-square-check text-2xl mr-3"></i><span><b>100%</b> improvements of at least 3 grades</span></div>
                </div>

                <router-link to="/tutors/mr-chua#teaching" class="mt-12 px-5 py-1 inline-block btn">My Teaching Methodology</router-link>
            </div>
        </div>

        <div id="teaching" class="bg-gray-800 text-white grid grid-cols-2 gap-12 lg:gap-32 xl:px-32 lg:px-16 py-20 px-6">
            <div class="tutorDesc mt-10 col-span-2 lg:col-span-1 ml-auto">
                <h5 class="font-extrabold text-3xl">MOE-Registered Gallup StrengthsFinder Trainer</h5>
                <p class="mt-4 leading-relaxed">I create memorable techniques and acronyms to help build a strong foundation in <b>M</b>athematics for your child.</p>
                <p class="mt-4 leading-relaxed">My top 5 strengths are <b>D</b>eveloper, <b>R</b>elator, <b>A</b>rranger, <b>E</b>mpathy, <b>I</b>ndividualisation.</p>
                <p class="mt-4 leading-relaxed">Shuffling the alphabets above, it is my motto to improve each students’ Individual strengths, and make their <b>DREAM(ath)</b> come true!</p>

                <router-link to="/tutors/mr-chua#testimonials" class="mt-12 px-5 py-1 inline-block btn-inverse">My Testimonials</router-link>
            </div>
            <div class="col-span-2 lg:col-span-1">
                <img src="/assets/chuaTeaching.png" alt="Mr Chua photo" class="rounded-lg mr-auto tutorPhoto object-contain object-left" />

                <a href="/assets/mr-chua-strengths.pdf" class="mt-12 px-5 py-1 inline-block btn-inverse">Read More Here</a>
            </div>
        </div>

        <div id="testimonials" class="bg-gray-50 xl:px-32 lg:px-16 py-20 px-6 text-center">
            <div class="wideTutorDesc mx-auto">
                <h5 class="font-extrabold text-3xl">The Goal is To Enjoy Learning Mathematics. Results come with it.</h5>
                <p class="mt-4 leading-relaxed lg:w-2/3 mx-auto">Building a repertoire of experience with weaker students, I have developed a <b>1 to 1 mix-coaching program</b>; creating an effective learning environment that focuses on self-practice and retention training. See what some parents say!</p>
                <div class="order-1 col-span-4 lg:col-span-2 mx-auto mt-12 mb-12 lg:mb-0 carousel relative lg:rounded-lg overflow-hidden">
                    <div class="carousel-inner relative overflow-hidden">
                        <!--Slide 1-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-1"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        checked="checked"
                        />
                        <div
                        class="carousel-item absolute opacity-0 bg-center"
                        style="
                            background: url(/assets/chua/testimonial2.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-7"
                        class="
                            control-1
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                            flex
                            justify-center
                            content-center
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-2"
                        class="
                            next
                            control-1
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 2-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-2"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0 bg-center"
                        style="
                            background: url(/assets/chua/testimonial4.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-1"
                        class="
                            control-2
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-3"
                        class="
                            next
                            control-2
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 3-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-3"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0"
                        style="
                            background: url(/assets/chua/testimonial3.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-2"
                        class="
                            control-3
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-4"
                        class="
                            next
                            control-3
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 4-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-4"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0"
                        style="
                            background: url(/assets/chua/testimonial1.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-3"
                        class="
                            control-4
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-5"
                        class="
                            next
                            control-4
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 5-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-5"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0"
                        style="
                            background: url(/assets/chua/testimonial5.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-4"
                        class="
                            control-5
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-6"
                        class="
                            next
                            control-5
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 6-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-6"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0"
                        style="
                            background: url(/assets/chua/testimonial6.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-5"
                        class="
                            control-6
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-7"
                        class="
                            next
                            control-6
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!--Slide 7-->
                        <input
                        class="carousel-open"
                        type="radio"
                        id="carousel-7"
                        name="carousel"
                        aria-hidden="true"
                        hidden=""
                        />
                        <div
                        class="carousel-item absolute opacity-0"
                        style="
                            background: url(/assets/chua/testimonial7.png), #e5e7eb;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        "
                        ></div>
                        <label
                        for="carousel-6"
                        class="
                            control-7
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            ml-2
                            md:ml-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            left-0
                            my-auto
                        "
                        ><i class="fas fa-angle-left mt-3"></i
                        ></label>
                        <label
                        for="carousel-1"
                        class="
                            next
                            control-7
                            w-10
                            h-10
                            transition
                            ease-linear
                            hover:bg-gray-800
                            hover:text-white
                            mr-2
                            md:mr-10
                            absolute
                            cursor-pointer
                            hidden
                            font-bold
                            text-black
                            rounded-full
                            bg-white
                            leading-tight
                            text-center
                            z-10
                            inset-y-0
                            right-0
                            my-auto
                        "
                        ><i class="fas fa-angle-right mt-3"></i
                        ></label>

                        <!-- Add additional indicators for each slide -->
                        <ol class="carousel-indicators">
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-1"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-2"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-3"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-4"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-5"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-6"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                            <li class="inline-block mr-3 lg:mr-2">
                                <label
                                for="carousel-7"
                                class="
                                    carousel-bullet
                                    cursor-pointer
                                    block
                                    text-2xl text-gray-400
                                    hover:text-gray-600
                                "
                                >•</label
                                >
                            </li>
                        </ol>
                    </div>
                </div>

                <router-link to="/tutors/mr-chua#beyond" class="mt-12 px-5 py-1 inline-block btn">Beyond Math</router-link>
            </div>
        </div>

        <div id="beyond" class="bg-gray-800 text-center text-white xl:px-32 lg:px-16 py-20 px-6">
            <h5 class="font-extrabold text-3xl">As the Principal Coach, We Support Dreams Beyond Mathematics</h5>
            <p class="wideTutorDesc mt-4 leading-relaxed mx-auto lg:w-2/3 mx-auto">We help our students achieve their dreams in the education system today. Since 2020, we have achieved an <b>85% Direct-School-Admission (Primary to Secondary) or Early-Admission-Exercise (Secondary to Tertiary)</b> rate for our students that pursues their dream schools or course.</p>
            
            <div class="grid grid-cols-3 gap-12 wideTutorDesc mx-auto mt-12 lg:mb-0 relative lg:rounded-lg overflow-hidden">
                <img src="/assets/testimonials/coaching1.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
                <img src="/assets/testimonials/coaching2.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
                <img src="/assets/testimonials/coaching3.png" alt="Coaching testimonial picture" class="col-span-3 lg:col-span-1 w-full border-2 shadow-sm rounded-lg" />
            </div>

            <router-link to="/tutors/mr-chua#final" class="mt-12 px-5 py-1 inline-block btn-inverse">Final Message</router-link>
        </div>

        <div id="final" class="bg-gray-50 xl:px-32 lg:px-16 py-20 px-6 text-center">
            <div class="wideTutorDesc mx-auto lg:w-2/3">
                <h5 class="font-extrabold text-3xl">Humble Beginnings, to Rescue students from Mathematics</h5>
                <p class="mt-4 leading-relaxed mx-auto">Over six years of regular tutoring and 200> hours of training experiences in MOE schools, training at juvenile homes and working with a range of students with special needs, I have learnt and developed my pedagogy to suit every child that comes to us. Alongside Mr. Lai, we will serve as mentors for your child, ensuring that they will grow and navigate their examinations safely.</p>
                <p class="mt-4 leading-relaxed text-xl">Inviting you to sign up or have a chat with me!</p>

                <div class="flex gap-4 lg:gap-8 justify-center">
                    <router-link to="/contact" class="mt-12 px-5 py-1 inline-block btn">Enroll Now</router-link>
                    <a href="https://wa.me/%2B6580426428" class="mt-12 px-5 py-1 inline-block btn">My Whatsapp</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tutors",
    props: {
        tutor: String
    },
    mounted() {
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #tutorContainer {
        margin-top: calc(50px + 0.5rem);
    }

    .carousel {
        height: auto;
        width: 100%;
    }

    .carousel-item {
        -webkit-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
        padding-top: 100%;
        width: 100%;
    }
}

@media only screen and (min-width : 1024px) {
    #tutorContainer {
        margin-top: calc(70px + 0.5rem);
    }

    .carousel {
        height: 70vh;
        width: auto;
        aspect-ratio: 1/1;
    }

    .carousel-item {
        -webkit-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
        padding-top: 100%;
        width: 100%;
    }

    .tutorPhotoWidth {
        width: 500px;
    }

    .tutorPhoto {
        width: 500px;
        height: 670px;
    }

    .tutorDesc { 
        width: 500px;
    }

    .wideTutorDesc {
        width: calc(1000px + 8rem);
    }

}

.carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4,
#carousel-5:checked ~ .control-5,
#carousel-6:checked ~ .control-6,
#carousel-7:checked ~ .control-7 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 1%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked
~ .control-1
~ .carousel-indicators
li:nth-child(1)
.carousel-bullet,
#carousel-2:checked
~ .control-2
~ .carousel-indicators
li:nth-child(2)
.carousel-bullet,
#carousel-3:checked
~ .control-3
~ .carousel-indicators
li:nth-child(3)
.carousel-bullet,
#carousel-4:checked
~ .control-4
~ .carousel-indicators
li:nth-child(4)
.carousel-bullet,
#carousel-5:checked
~ .control-5
~ .carousel-indicators
li:nth-child(5)
.carousel-bullet,
#carousel-6:checked
~ .control-6
~ .carousel-indicators
li:nth-child(6)
.carousel-bullet,
#carousel-7:checked
~ .control-7
~ .carousel-indicators
li:nth-child(7)
.carousel-bullet {
    color: white;
}
</style>