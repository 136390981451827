<template>
    <div v-if="windowWidth < 992" id="navContainer" class="lg:hidden fixed top-0 border-b z-50 w-full lg:px-14 xl:px-40 px-4 py-2 bg-white">
        <div id="navbar" class="flex justify-between">
            <router-link to="/"><img src="/assets/logo.png"></router-link>
            <button aria-label="Toggle Navigation Menu" class="text-xl lg:text-3xl self-center hover:text-gray-400" @click="toggleMenu"><i class="fa-solid fa-bars"></i></button>
        </div>
    </div>

    <div v-if="windowWidth < 992" id="navMenu" class="lg:hidden z-50 bg-white flex flex-col border-l shadow w-2/3 lg:w-2/5 xl:w-1/5 h-screen hidden fixed top-0 right-0 px-8 py-6 lg:px-10 lg:py-8">
        <button class="text-3xl self-end mb-6 hover:text-gray-400" @click="toggleMenu"><i class="fa-solid fa-times"></i></button>
        <router-link to="/" class="text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleMenu">Home</router-link>
        <a class="text-xl mb-2 hover:underline hover:text-gray-400 pr-10 lg:pr-0" @click="toggleTutors">Our Tutors<i class="tutorsChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm"></i></a>
        <div class="flex-col flex hidden my-2 py-2 border-t border-b tutorsLinks">
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/tutors/mr-lai">Mr. Lai (Principal Tutor & Scholar)</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/tutors/mr-chua">Mr. Chua (Principal Coach & Developer)</router-link></div>
        </div>
        <a class="text-xl mb-2 hover:underline hover:text-gray-400 pr-10 lg:pr-0" @click="toggleProgrammes">Programmes & Rates<i class="programmesChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm"></i></a>
        <div class="flex-col flex hidden my-2 py-2 border-t border-b programmesLinks">
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/lower-pri">Lower Primary (P1-3)</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/upper-pri">PSLE Math (P4-6)</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/sec-express">O Levels EMath / AMath</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/sec-na-nt">N Levels EMath / AMath</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/junior-college">Junior College Math</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/private-candidates">Private Candidates</router-link></div>
            <div class="ml-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/2024-schedule">Schedule</router-link></div>
        </div>
        <a class="text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleTestimonials">Testimonials<i class="testimonialsChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm" /></a>
        <div class="flex-col flex hidden my-2 py-2 border-t border-b testimonialsLinks">
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/testimonials">Student Endorsements</router-link></div>
            <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/results">Results & Achievements</router-link></div>
        </div>
        <router-link to="/faq" class="text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleMenu">FAQ</router-link>
        <router-link to="/contact" class="text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleMenu">Enroll Now!</router-link>
    </div>
    <nav v-if="windowWidth >  992" class="flex bg-white z-50 fixed w-full py-2 top-0 lg:px-14 xl:px-28 px-4 items-center justify-between">
        <router-link to="/"><img src="/assets/logo.png"></router-link>
        <div class="flex lg:gap-6">
            <!-- <router-link to="/" class="whitespace-nowrap text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleMenu">Home</router-link> -->
            <div class="relative lg:pr-4 2xl:pr-8">
                <a class="whitespace-nowrap text-xl mb-2 hover:underline hover:text-gray-400 pr-10 lg:pr-0" @click="toggleTutors">Our Tutors<i class="tutorsChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm"></i></a>
                <div class="flex-col flex hidden my-2 py-2 border tutorsLinks absolute bg-white rounded w-full z-50 px-1.5">
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/tutors/mr-lai">Mr. Lai (Principal Tutor & Scholar)</router-link></div>
                    <div class="ml-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/tutors/mr-chua">Mr. Chua (Principal Coach & Developer)</router-link></div>
                </div>
            </div>
            <div class="relative lg:pr-4 2xl:pr-8">
                <a class="whitespace-nowrap text-xl mb-2 hover:underline hover:text-gray-400 pr-10 lg:pr-0" @click="toggleProgrammes">Programmes & Rates<i class="programmesChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm"></i></a>
                <div class="flex-col flex hidden my-2 py-2 border programmesLinks absolute bg-white rounded w-full z-50 px-1.5">
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/lower-pri">Lower Primary (P1-3)</router-link></div>
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/upper-pri">PSLE Math (P4-6)</router-link></div>
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/sec-express">O Levels EMath / AMath</router-link></div>
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/sec-na-nt">N Levels EMath / AMath</router-link></div>
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/junior-college">Junior College Math</router-link></div>
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/private-candidates">Private Candidates</router-link></div>
                    <div class="ml-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/programmes/2024-schedule">Schedule</router-link></div>
                </div>
            </div>
            <div class="relative lg:pr-4 2xl:pr-8">
                <a class="whitespace-nowrap text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleTestimonials">Testimonials<i class="testimonialsChevron cursor-pointer align-middle ml-2 fa-solid fa-chevron-down text-sm" /></a>
                <div class="flex-col flex hidden my-2 py-2 border testimonialsLinks absolute bg-white rounded w-full z-50 px-1.5">
                    <div class="ml-2 mb-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/testimonials">Student Endorsements</router-link></div>
                    <div class="ml-2 hover:underline hover:text-gray-400"><router-link @click="toggleMenu" to="/results">Results & Achievements</router-link></div>
                </div>
            </div>
            <router-link to="/faq" class="whitespace-nowrap text-xl mb-2 lg:pr-4 2xl:pr-8 hover:underline hover:text-gray-400" @click="toggleMenu">FAQ</router-link>
            <router-link to="/contact" class="whitespace-nowrap text-xl mb-2 hover:underline hover:text-gray-400" @click="toggleMenu">Enroll Now!</router-link>
        </div>
    </nav>
</template>

<script>
import gsap from "gsap";

export default {
    name: "Navbar",
    methods: {
        toggleMenu() {
            const menu = document.querySelector("#navMenu");
            // Current state of menu
            const active = !menu.classList.contains("hidden");
            if (active) {
                gsap.fromTo("#navMenu", { xPercent: 0 }, { xPercent: 100, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                }, 300)
            } else {
                gsap.fromTo("#navMenu", { xPercent: 100 }, { xPercent: 0, duration: 0.2 });
                menu.classList.toggle("hidden");
            }
        },
        toggleProgrammes() {
            const menu = document.querySelector(`.programmesLinks`);
            let active = !menu.classList.contains("hidden");

            if (active) {
                gsap.to(`.programmesLinks`, { opacity: 0, yPercent: -30, duration: 0.2 });
                gsap.to(`.programmesChevron`, { rotateX: 0, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    active = false;
                }, 300)
            } else {
                gsap.fromTo(`.programmesLinks`, { opacity: 0, yPercent: -50 }, { opacity: 1, yPercent: 0, duration: 0.2 });
                gsap.to(`.programmesChevron`, { rotateX: 180, duration: 0.3 });
                menu.classList.toggle("hidden");
                active = true;
            }
        },
        toggleTestimonials() {
            const menu = document.querySelector(`.testimonialsLinks`);
            let active = !menu.classList.contains("hidden");

            if (active) {
                gsap.to(`.testimonialsLinks`, { opacity: 0, yPercent: -30, duration: 0.2 });
                gsap.to(`.testimonialsChevron`, { rotateX: 0, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    active = false;
                }, 300)
            } else {
                gsap.fromTo(`.testimonialsLinks`, { opacity: 0, yPercent: -50 }, { opacity: 1, yPercent: 0, duration: 0.2 });
                gsap.to(`.testimonialsChevron`, { rotateX: 180, duration: 0.3 });
                menu.classList.toggle("hidden");
                active = true;
            }
        },
        toggleTutors() {
            const menu = document.querySelector(`.tutorsLinks`);
            let active = !menu.classList.contains("hidden");

            if (active) {
                gsap.to(`.tutorsLinks`, { opacity: 0, yPercent: -30, duration: 0.2 });
                gsap.to(`.tutorsChevron`, { rotateX: 0, duration: 0.3 });
                setTimeout(() => {
                    menu.classList.toggle("hidden");
                    active = false;
                }, 300)
            } else {
                gsap.fromTo(`.tutorsLinks`, { opacity: 0, yPercent: -50 }, { opacity: 1, yPercent: 0, duration: 0.2 });
                gsap.to(`.tutorsChevron`, { rotateX: 180, duration: 0.3 });
                menu.classList.toggle("hidden");
                active = true;
            }
        }
    },
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener("scroll", () => {
            if (this.windowWidth >= 992) {
                const tutorMenu = document.querySelector(`.tutorsLinks`);
                tutorMenu.classList.add("hidden");
                gsap.to(`.tutorsChevron`, { rotateX: 0, duration: 0.3 });
                const testiMenu = document.querySelector(`.testimonialsLinks`);
                testiMenu.classList.add("hidden");
                gsap.to(`.testimonialsChevron`, { rotateX: 0, duration: 0.3 });
                const programmeMenu = document.querySelector(`.programmesLinks`);
                programmeMenu.classList.add("hidden");
                gsap.to(`.programmesChevron`, { rotateX: 0, duration: 0.3 });
            }
        })
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {   
    #navbar img {
        height: 50px;
    }
    nav img {
        height: 50px;
    }
}

@media only screen and (min-width : 992px) {
    #navbar img {
        height: 70px;
    }
    nav img {
        height: 70px;
    }
}
</style>