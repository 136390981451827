<template>
    <div id="resources" class="">
        <div id="container" class="text-4xl text-white flex justify-center items-center">
            Coming soon!
        </div>
    </div>
</template>

<style scoped>
@media only screen and (min-width : 320px) {
    #resources {
        margin-top: calc(50px + 0.5rem);
        background: url("/assets/picture2.jpg");
        background-size: cover;
    }
    #container {
        background: rgba(55, 65, 81, 0.75);
        min-height: 70vh;
    }
}

@media only screen and (min-width : 1024px) {
    #resources {
        margin-top: calc(70px + 0.5rem);
        background: url("/assets/picture2.jpg");
        background-size: cover;
    }
    #container {
        background: rgba(55, 65, 81, 0.75);
        min-height: 70vh;
    }
}
</style>

<script>
export default {
    name: "Resources",
    data() {
    },
    components: {
    },
    mounted() {
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
    }
}
</script>