<template>
  <head>
    <title>Sign Up Now 2023 | Math Vigilante</title>
  </head>
  <a href='https://wa.me/+6580426428' target="_blank"><svg class='fixed right-5 bottom-5 h-12 w-12 p-1.5 whatsapp rounded-lg shadow bg-green-600 hover:bg-transparent transition border border-green-600' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></a>
  <div id="hero" class="bg-gray-800 text-white pt-14 pb-14 lg:pb-20 lg:px-20 px-6">
    <div class="content flex flex-col lg:flex-row gap-14 lg:gap-24">
      <div id="left" class="lg:w-1/2 mt-4">
        <img src="/assets/logo.png" alt="MathVigilante Logo" class="mb-6 mx-auto bg-gray-50 rounded-lg py-2 px-4" width='280'>
        <p class="text-lg">Are you a parent who’s worried about your O or N Level child not doing well in Math?</p>
        <br />
        <p class="text-lg">Is PSLE Math getting too difficult and you want some last minute revision package?</p>
        <br />
        <p class="text-lg">Or are you one of those students that are still doing poorly for Mathematics and not sure why even though you studied?</p>
        <ul class="mt-10 flex-col gap-4 flex">
          <li class="flex gap-2.5"><span>❌</span>Is your child unable to obtain his/her A1-B3 grade for EMath and/or AMath?</li>
          <li class="flex gap-2.5"><span>❌</span>Lost confidence in understanding Mathematics concepts?</li>
          <li class="flex gap-2.5"><span>❌</span>Discouraged and struggling to keep up in class?</li>
          <li class="flex gap-2.5"><span>❌</span>Wished that you had easy to digest notes, summary notes and targeted practice questions for your child or yourself?</li>
        </ul>
        <p class="italic mt-8">Sign up with us today to learn more!</p>
      </div>

      <div class="border border-gray-600 opacity-70"></div>

      <div id="right" class="lg:w-1/2 rounded-lg">
        <div class="overlay rounded-lg">
        </div>
        <div id='inner' class="pb-2">
          <h2 class="bg-gray-700 rounded-t-lg text-center font-bold tracking-wide text-2xl uppercase mb-8 px-6 py-4">
            Join us today to achieve your <span class="text-red-500">A</span> in mathematics and save up to $200!
          </h2>
          <div class="px-6 py-2">
            <p>Signing up now will grant you the following benefits:</p>

            <ul class="mt-4 flex flex-col gap-4">
              <li class="flex gap-2.5"><span>✅</span>Free diagnosis for your child (UP $70)</li>
              <li class="flex gap-2.5"><span>✅</span>Waiver on enrollment fee (UP $50)</li>
              <li class="flex gap-2.5"><span>✅</span>No Material Fee (UP $30)</li>
              <li class="flex gap-2.5"><span>✅</span>No Deposits (UP $50)</li>
              <li class="flex gap-2.5"><span>✅</span>Financial assistance available</li>
              <li class="flex gap-2.5"><span>✅</span>Hybrid and physical classes available</li>
            </ul>

            <p class="italic mt-10 mb-2">Slots are running out fast!</p>

            <a href='contact' class="bg-red-500 px-6 inline-block py-1 text-lg rounded-lg border-red-500 border hover:bg-transparent transition">Register NOW to avoid disappointment</a>
            <p class="text-sm text-gray-300 mt-10">Offer only valid for the first 10 who signs up (fastest fingers first)</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="testimonials" class="bg-gray-50 pt-14 pb-14 lg:px-20 px-6">
    <div class="content text-center">
      <p class="">As the major exam season enters into the 150 day mark, we are releasing our FINAL class slots for  our PSLE, N and O Level Programs.</p>
      <br/>
      <p>These slots are designed to specifically tackle the major end of year examinations with impactful pedagogies to pass down the right mindset, skills and techniques tot answer math questions.</p>
      <br/>
      <p>Classes are small and limited as we focus on coaching in a smaller group setting. Check out how classes are done below!</p>
      <br/>
      <p class="font-bold underline mb-2">2022 Major Exams Result</p>
      <p>100% PSLE AL1-3 or 4-Grade Jumps</p>
      <p>100% N Level A1/2 or 4-Grade Jumps</p>
      <p>94% O Level A1/2 or 4-Grade Jumps</p>
      <video class="lg:w-2/3 mx-auto my-12 rounded-lg" playsinline autoplay loop muted>
            <source src="/assets/timelapse.mp4" type="video/mp4">
      </video>
      <a href='contact' class="bg-red-500 px-6 py-1 inline-block text-lg rounded-lg border-red-500 border hover:text-gray-800 hover:bg-transparent transition text-white">Sign up now before its too late!</a>
    </div>
  </div>

  <div id="teachers" class="bg-gray-800 text-white pt-14 pb-14 lg:px-20 px-6">
    <div class="content flex-col xl:flex-row flex gap-14">
      <div class="flex xl:flex-col justify-between gap-4 xl:w-1/5">
        <img src="/assets/mrLai.jpg" alt="Tutor Mr Lai photo" class="w-2/5 xl:w-full rounded-lg" />
        <img src="/assets/mrChua.jpg" alt="Tutor Mr Chua photo" class="w-2/5 xl:w-full rounded-lg" />
      </div>
      <div class="xl:mt-4">
        <br />
        <p class="text-lg">Across 15 years of teaching for the both of us, we have developed strategies and notes that are adaptable and easy to understand.</p>
        <br />
        <p class="text-lg"><span class="font-bold">PSLE Math</span> is about being able to breakdown the questions using some English story-telling techniques and models, whereas <span class='font-bold'>Secondary school EMath and AMath</span> must be conquered with the right skillset and practice!</p>
        <br />
        <p class="text-lg xl:mt-14">This isn’t a normal Mathematics Tuition Centre. Not only do we cater to the O level students, we are also the <span class="font-bold">FIRST EVER N LEVEL MATH TUITION CENTRE!</span></p>
        <br />
        <p>Read on below to find out more about us or<a href='contact' class="inline-block mt-3 md:mt-0 md:ml-3 bg-red-500 px-6 py-1 text-lg rounded-lg border-red-500 border hover:bg-transparent transition text-white">Sign Up Now!</a></p>
      </div>
    </div>
  </div>

  <div id="testimonials" class="bg-gray-50 pt-14 pb-14 lg:px-20 px-6">
    <div class="content">
      <p class="text-lg underline">Some of our 2022 PSLE, O and N Levels Testimonials:</p>
      <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-6">
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “XXX did so well and I’m very thankful. He scored an <span class="underline text-red-500 font-semibold">AL1</span> for math! Total score was AL8. He’s very happy. Thank you so much for journeying with him, Ding En. You have helped him attain what he wanted. This morning he said all he wanted to see on his result slip was a AL1 for math.😆”
          <p class="mt-4 italic text-sm">– Parent of PSLE Student 2022</p>
        </div>
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “Teacher Ding En always helps me with the math questions that I don’t know and explains the solution to me clearly and makes sure that I really understand them. He helped me improve my prelim results from an <span class="text-red-500 underline font-semibold">AL4 in prelim to an AL1</span> in PSLE. Overall teacher Ding En is a very helpful and kind teacher! Thank you Teacher Ding En”
          <p class="mt-4 italic text-sm">– PSLE Student 2022</p>
        </div>
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “When I first joined Math Vigilante, I struggled with some topics. Ding En helped me improve my understanding in math and lessened my careless mistakes. At some point I also <span class="text-red-500 underline font-semibold">started to enjoy doing math</span> after I realized it wasn’t as hard as I thought. After many lessons I managed to improve from <span class="text-red-500 underline font-semibold">E8 to A1</span> during N level! Thank you so much Ding En!!”
          <p class="mt-4 italic text-sm">– Sec 4 N Level Student 2022</p>
        </div>
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “Mr Chua has always been a <span class="text-red-500 underline font-semibold">patient teacher</span> and aims to help me achieve better than my desired grade, as well as plans strategies with me to ensure that I am able to excel in math. He also <span class="text-red-500 underline font-semibold">prioritises my weaker topics</span> and slows down to ensure that I am able to fully grasp the topic well, along with extra practice questions to revise on.”
          <p class="mt-4 italic text-sm">– Sec 4 N Level Student 2022</p>
        </div>
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “I can also proudly say that my maths has improved by a lot because <span class="text-red-500 underline font-semibold">he didn’t give up on me</span> and continued pushing me even when I was about to give up. I can definitely say that Ding En <span class="text-red-500 underline font-semibold">goes the extra mile</span> to really help me and even <span class="text-red-500 underline font-semibold">volunteered to stay back even later than usual</span> just to help me with my maths questions.”
          <p class="mt-4 italic text-sm">– O Level Private Candidate 2022</p>
        </div>
        <div class="col-span-1 bg-gray-200 px-4 py-3 rounded-lg">
          “Mr Lai started tutoring me in amath from last July when I was failing. He is a <span class="text-red-500 underline font-semibold">caring and patient teacher</span> who makes sure that I understand all the math concepts and gives me a variety of questions to practice with during class. Mr Lai also <span class="text-red-500 underline font-semibold">sent me extra resources</span> for me to do myself and explained schoolwork I had difficulty with outside class. <span class="text-red-500 underline font-semibold">With his help, I managed to get an A1</span> in my O level exams.”
          <p class="mt-4 italic text-sm">– Sec 4 O Level 2022</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-14 pb-14 lg:px-20 px-6 bg-gray-800 text-white">
    <div class="content flex lg:flex-row flex-col justify-between gap-14">
      <div class="lg:order-1 order-2 lg:w-3/5">
        <p class="text-lg">For all our <span class="font-bold">PSLE O and N Level Math Programs</span>, we:</p>
        <ul class="flex gap-4 flex-col mt-4">
          <li class="flex gap-2.5"><span>✅</span>pride ourselves on being able to deliver results-driven tuition while maintaining a fun, cheerful and hardworking environment.</li>
          <li class="flex gap-2.5"><span>✅</span>believe that every student has the potential in Mathematics. That is why we have also developed a N Level pedagogy; designed to help weaker students grasp mathematics better - even in the O level classes!</li>
          <li class="flex gap-2.5"><span>✅</span>also know that smarter students will need some tough challenges - rest assured we have accumulated over 10 years worth of questions and brain teasers to ensure they remain tip-top in their examinations</li>
          <li class="flex gap-2.5"><span>✅</span>offer a proven track record (jump in 4 grades or 86% Distinction), qualified teachers, and a curriculum that is trusted and reliable.</li>
          <li class="flex gap-2.5"><span>✅</span>know that every student is unique, and that's why we offer a free diagnosis of your child's weaknesses. This allows us to tailor our expert tips, methods, and strategies to your child's specific needs.</li>
          <li class="flex gap-2.5"><span>✅</span>keep our tuition classes affordable and flexible - to ensure that the student understands our learning styles before committing. And for a limited time only, we are waiving the enrolment fee for new students. Online and physical classes are available too</li>
          <li class="flex gap-2.5"><span>✅</span>give you the flexibility to choose the type of class (online or physical) that works best for you and your child while still receiving the same high-quality mathematics education.</li>
          <li class="flex gap-2.5"><span>✅</span>are conveniently located in Parkway / Katong and  Hougang, making it easy for you to find a tuition centre near you in if you stay in the east</li>
          <li class="flex gap-2.5"><span>✅</span>have many satisfied families who have entrusted their child's education to our industry-leading tuition centre.</li>
        </ul>

        <p class="text-lg mt-10 mb-5">Sign up today and give your child the tools they need to succeed.</p>
        <a href='contact' class="bg-red-500 px-6 py-1 text-lg rounded-lg border-red-500 border hover:bg-transparent transition inline-block">Sign up NOW</a>

      </div>
      <img src="/assets/picture3.jpg" alt="Tuition centre photo" class="order-1 lg:order-2 lg:w-1/3 object-cover rounded-lg">
    </div>
  </div>

  <div class="bg-gray-50 pt-14 pb-14 lg:px-20 px-6 ">
    <div class="content">
      <h2 class="text-lg underline">See what parents and students say on Google:</h2>
      <div class="grid grid-cols-4 gap-12 mx-auto mt-6 mb-12 lg:mb-0 carousel relative lg:rounded-lg">
          <img src="/assets/testimonials/google1.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
          <img src="/assets/testimonials/google2.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
          <img src="/assets/testimonials/google3.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
          <img src="/assets/testimonials/google4.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
          <img src="/assets/testimonials/google5.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
          <img src="/assets/testimonials/google6.png" alt="Google review picture" class="col-span-4 lg:col-span-2 w-full rounded-lg" />
      </div>

      <div class="text-center">
        <p class="font-bold text-xl text-red-500 mt-14 lg:mt-24 text-center">Last chance now! Seats are extremely limited! Register before it's too late...</p>
        <a href='contact' class="bg-red-500 px-6 py-1 text-lg rounded-lg border-red-500 border hover:text-gray-800 hover:bg-transparent transition text-white mx-auto mt-4 inline-block">Sign up now!</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpNow",
  mounted() {
    document.title = 'Sign Up Now 2023 | Math Vigilante'
  }
}
</script>

<style scoped>
.whatsapp {
  fill: white;
}

.whatsapp:hover {
  fill: #16a34a;
}

.content {
  max-width: 1280px;
  margin: auto;
}

#right {
  background: url('/assets/psle.jpg') bottom;
  background-size: cover;
  min-height: 50vh;
  position: relative;
}

#right > .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0,0,0,0.78);
  z-index: 10;
  backdrop-filter: blur(6px);
}

#right > #inner {
  position: relative;
  z-index: 20;
}

#teachers img {
}
</style>