<template>
    <div v-if="programName!='2024 Schedule'" class="programHeader bg-gray-50 grid grid-cols-5 lg:px-20 xl:px-40">
        <img :src="`/assets/${photo}`" class="lg:h-4/5 lg:rounded-lg border-gray-800 lg:border-2 shadow mx-auto my-auto col-span-5 lg:col-span-2 object-cover" id="programPic" alt="Program picture">

        <div class="col-span-4 lg:col-span-3 my-6 lg:my-28">
            <div class="px-6 xl:px-16">
                <h2 class="font-extrabold tracking-wide text-3xl lg:text-4xl uppercase">{{ programName }}</h2>
                <h4 v-if="(program !=='sec-express') && (program !== 'sec-na-nt') && (program !== 'upper-pri')" class="mt-2 text-lg font-bold">{{ price }}</h4>
                <h4 v-if="(program ==='upper-pri')" class="mt-2 text-lg font-bold">{{ priceP4 }}</h4>
                <h4 v-if="(program ==='upper-pri')" class="mt-0 text-lg font-bold">{{ priceP5 }}</h4>
                <h4 v-if="program === 'sec-express'" class="mt-2 text-lg font-bold">S1 & S2: $270 for 4 lessons (2hrs each)</h4>
                <h4 v-if="program === 'sec-express'" class="text-lg font-bold">S3 & S4: $300 for 4 lessons (2hrs each)</h4>
                <h4 v-if="program === 'sec-na-nt'" class="mt-2 text-lg font-bold">S1 & S2: $270 for 4 lessons (2hrs each)</h4>
                <h4 v-if="program === 'sec-na-nt'" class="text-lg font-bold">S3 & S4: $300 for 4 lessons (2hrs each)</h4>
                <h4 v-if="program === 'sec-na-nt' || program === 'sec-express'" class="text-lg font-bold">Double Math Bundle: $280 per subject per 4 lessons</h4>
                
                <p v-if="program === 'sec-express'" class="text-gray-600 lg:text-lg mt-8">
                    Has your child had trouble learning algebra in their Sec 1 or 2 years? Most students struggle to transit from PSLE to Secondary School Math as <b>Algebra is not easy at all.</b>
                </p>

                <p class="text-gray-600 lg:text-lg mt-4">
                    {{ captions1 }}
                </p>
                <p class="text-gray-600 lg:text-lg mt-4">
                    {{ captions2 }}
                </p>
                <p class="text-gray-600 lg:text-lg mt-4" v-if="captions3">
                    {{ captions3 }}
                </p>

                <p class="text-gray-600 lg:text-lg mt-8">
                    {{ desc }}<br>
                </p>
                
                <router-link to="/contact" class="inline-block mt-10 hover:bg-transparent hover:text-gray-800 bg-gray-700 text-white border rounded-lg px-3 py-1 transition ease-linear mr-4">Get in touch</router-link>
            </div>
        </div>
    </div>

    <div v-else class="programHeader bg-gray-50 text-center lg:px-40 py-20 px-6"> 
        <h2 class="font-extrabold tracking-wide text-3xl lg:text-4xl uppercase">{{ programName }}</h2>
        <h4 class="text-lg font-bold my-10">{{ desc }}</h4>
        <div class="flex flex-col md:flex-row gap-4 items-center justify-center">
          <router-link to="/assets/2024-schedule.pdf" class="btn px-5 py-2 btn" target="_blank">Download 2024 Schedule</router-link>
          <router-link to="/contact" class="px-5 py-2 btn">Contact Us</router-link>
        </div>
    </div>

    <div id="benefits" class="bg-gray-800 text-white pb-20 px-6 lg:px-0">
        <div class="pt-20 pb-16 text-center font-extrabold tracking-wide text-4xl uppercase">Student Benefits</div>
        <div class="xl:w-2/3 mx-auto grid grid-cols-3">
            <div class="col-span-3 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/cheatsheet.svg" alt="Cheatsheet illustration">
                <p class="font-bold text-center mt-4">Personalised Exam Cheatsheets</p>
            </div>
            <div class="col-span-3 mt-10 lg:mt-0 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/guidance.svg" alt="Guidance illustration">
                <p class="font-bold text-center mt-4">24/7 Online Guidance</p>
            </div>
            <div class="col-span-3 mt-10 lg:mt-0 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/consultation.svg" alt="Consultation illustration">
                <p class="font-bold text-center mt-4">1 to 1 Consultations and Coaching</p>
            </div>
            <div class="col-span-3 mt-10 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/welfare.svg" alt="Welfare illustration">
                <p class="font-bold text-center mt-4">Get-Togethers and Welfare</p>
            </div>
            <div class="col-span-3 mt-10 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/handsOn.svg" alt="Hands on illustration">
                <p class="font-bold text-center mt-4">Hands-On Learning Approach</p>
            </div>
            <div class="col-span-3 mt-10 lg:col-span-1">
                <img class="mx-auto" src="/assets/illustrations/team.svg" alt="Team illustration">
                <p class="font-bold text-center mt-4">Holistic Learning through Teamwork</p>
            </div>
        </div>
    </div>

    <Action :colors="'inverse'" />
</template>

<script>
import Action from "../components/Action";

export default {
    name: "Program",
    data() {
        return {
            programName: "",
            captions: "",
            photo: "",
            price: "",
            desc: ""
        }
    },
    props: {
        program: String
    },
    components: {
        Action
    },
    methods: {
        getProgramData() {
            if (this.program == "2024-schedule") {
                this.programName = "2024 Schedule";
                this.desc = "Contact us for more details! Slots are running out!";
            } else if (this.program == "lower-pri") {
                this.programName = "Lower Primary P1-3";
                this.captions1 = "At Math Vigilante, we build a strong foundation and interest towards Mathematics while they are still young. This is to ensure that they build long-term confidence for the subject, and making it more inclined for them to perform better as they grow.";
                this.captions2 = "Furthermore, we strive to make PSLE Math Tuition meaningful and intuitive to learn at their age. Following closely to the MOE curriculum, we impart important ideas in Mathematical problem-solving alongside other forms of real-life applications that will help them grow in their early academic years.";
                this.captions3 = null
                this.photo = "/program/lowerpri.jpg";
                this.price = "$168 for 4 lessons (1.5hrs each)";
                this.desc = "Keen to try it out? Do enquire today for a trial!";
            } else if (this.program == "upper-pri") {
                this.programName = "PSLE Math P4-P6";
                this.captions1 = "At the Upper Primary level, we aim to impart more relevant examination skills for the students and build strong exam-answering techniques for the students. This is to ensure that they build confidence for the subject over time, helping them perform better during Examinations.";
                this.captions2 = "Following closely to the MOE curriculum, we will also introduce more problem-solving techniques, heuristics, and other forms of real-life applications that are becoming more frequent in the PSLE examinations.";
                this.captions3 = "Keen to try it out? Do enquire today for a trial!"
                this.photo = "/program/psle.jpg";
                this.priceP4 = "P4-5: $180 for 4 lessons (1.5hrs each)";
                this.priceP5 = "P6: $256 for 4 lessons (2hrs each)";
                this.desc = "P4 Classes are 1.5 hours long and occurs once a week. P5 and P6 classes are 2 hours long and occurs once a week as well.";
            } else if (this.program == "sec-express") {
                this.programName = "Secondary EMath and AMath Express (G3)";
                this.captions1 = "Hence, we build their foundation by breaking down Mathematical concepts into bite sized formats from the very beginning. Not only it will help make the learning process easier, but it will also develop your child’s critical thinking in the long-term.";
                this.captions2 = "Moving into Upper Secondary, many students often struggle to manage EMath properly; much less juggle between two EMath and AMath subjects if they are taking.";
                this.captions3 = "Following closely to MOE’s O Level Math’s syllabus, we inculcate a strong foundation to manipulate mathematics with clear answering techniques. This way, they will be trained to tackle difficult questions with ease."
                this.photo = "/program/olevels.jpg";
                this.price = "$260 per month (4 classes)";
                this.desc = "Keen to try it out? Do enquire today for a trial!";
            } else if (this.program == "sec-na-nt") {
                this.programName = "Secondary NA (G2) / NT (G1)";
                this.captions1 = 'Over the years of tutoring and coaching students, we are seeing more students struggling with Mathematics and finding themselves in the NA/NT band. The demand for clearer and more personalised explanations of Math concepts become very important for them – and at Math Vigilante, we do just that.';
                this.captions2 = 'Adopting a more flexible mix & match style, we prepare NA/NT students with more personalised learning approaches to build their foundation and subsequently performance. Through these positive learning experiences, our NA/NT students will be able to solidify their understanding of Mathematics better for examinations.';
                this.captions3 = null;
                this.photo = "/program/nlevels.jpg";
                this.price = "$280 per month (4 classes)";
                this.desc = "Each lesson is 2 hours long and occurs once a week.";
            } else if (this.program == "junior-college") {
                this.programName = "JC H1 Math H2 Math";
                this.captions1 = 'H1/H2 Mathematics require strong foundation and intensive practice for any students to perform in examinations. Thus, many students tend to not be able to perform as they do not have the required resources to do so.';
                this.captions2 = 'Taught by Mr Lai, his resources are simplified to provide the best learning experience for all our JC students. Mr Lai will strive to imparting the most important and necessary techniques to ensure that all our students will tackle challenging questions at A levels with ease.';
                this.captions3 = null;
                this.photo = "secExpress.jpg";
                this.price = "$40/h, $320 per month (4 classes)";
                this.desc = "Each lesson is 2 hours long and occurs once a week.";
            } else if (this.program == "private-candidates") {
                this.programName = "Private Candidates";
                this.captions1 = 'At Math Vigilante, we understand that not all students fit the current curriculum or learning methods in school. Thus, we have developed a new 1 to 1 mix-coaching program led by Mr. Chua.';
                this.captions2 = 'This program serves as an important step to build a foundation for the student to grasp Mathematics in a light-hearted manner while retaining the important steps to solving questions for the National Examinations. This program will be individually designed for every student that comes to us for rescue!';
                this.captions3 = null;
                this.photo = "psle.jpg";
                this.price = "Price Varies Depending on Diagnosis";
                this.desc = "Each lesson is minimally 2 hours long; with free extension of classes if needed. Fees will be pro-rated according to the number of lessons in a month.";
            }
        }
    },
    updated() {
        this.getProgramData();
    },
    mounted() {
        this.getProgramData();
        const container = document.querySelector("#programPic");
        container.style.background = `url("/assets/${this.photo}")`;
        document.querySelector('meta[name="description"]').setAttribute("content", '100% AL1-3 for 2022 PSLE Math, 100% Distinction of 5-Grade Improvement for N Level Math 2022; Math Vigilante provides quality Maths Tuition for PSLE Math,  O Level AMath EMath and N Level Math in Singapore.');
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    .programHeader {
        margin-top: calc(50px + 0.5rem);
    }

    #programPic {
        width: 100%;
        max-height: 600px;
    }
}

@media only screen and (min-width : 1024px) {
    .programHeader {
        margin-top: calc(70px + 0.5rem);
    }
 
    #programPic {
        width: 100%;
        max-height: 100%;
    }
}

#programPic {
    object-position: 35% 50%;
}

#benefits img {
    height: 200px;
    width: 200px;
}
</style>