<template>
    <div id="testimonialContainer" class="bg-gray-50">
        <div class="grid grid-cols-2 xl:w-2/3 py-14 lg:py-16 mx-6 xl:mx-auto">
            <div class="col-span-2 pb-6 lg:pb-10 text-center font-extrabold tracking-wide text-4xl uppercase">Our students love us!</div>
            <div class="col-span-2 lg:col-span-1 hover:shadow-lg transition ease-linear bg-gray-100 shadow lg:mr-6 my-5 py-6 lg:px-8 px-4">
                <div class="lg:flex items-center">
                    <img src="/assets/testimonials/justinMoh.webp" alt="Justin Moh photo" class="border-2 border-gray-800 rounded-full mx-auto lg:mr-auto">
                    <div class="mt-4 lg:mt-0 lg:ml-6">
                        <h3 class="font-bold text-lg">Justin Moh</h3>
                        <h4 class="mb-2">Hua Yi Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Patient and effective, Mr. Lai goes the extra mile and helps me in his own time..."</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">When starting, my results weren’t very good with me being almost clueless with AMath. But, Mr. Lai was patient with me and taught me the topics that I was uncertain about. He uses the iPad effectively, showing the explanations behind certain steps while also helping visualise questions in a simpler way. Even after lessons, if I had any questions or doubts, Mr. Lai goes the extra mile and help me in his own time. The questions he gives as homework are sometimes on the tough side, but he always patiently goes through those that I’m unfamiliar with in tuition class.</div>
            </div>
            <div class="col-span-2 lg:col-span-1 hover:shadow-lg transition ease-linear bg-gray-100 shadow lg:ml-6 my-5 py-6 lg:px-8 px-4">
                <div class="lg:flex items-center">
                    <img src="/assets/testimonials/aymericLee.webp" alt="Aymeric Lee photo" class="border-2 border-gray-800 rounded-full mx-auto lg:mr-auto">
                    <div class="mt-4 lg:mt-0 lg:ml-6">
                        <h3 class="font-bold text-lg">Aymeric Lee</h3>
                        <h4 class="mb-2">Serangoon Gardens Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Mr. Lai's lessons are one of a kind to me!"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">He is one of the rare few teachers that teaches with an iPad. His teaching skills are also very good as he would explain it in the most efficient way possible just to get his students to an A1. Whenever I needed help, he was always responsive even by text, and explains his steps clearly to make sure I understood the concepts required. The pace of the lessons is quite fast but it is always adjustable as long as you inform him. He slows down and pays attention to the weaker chapters I have before moving on to the next chapter. Overall, he is a very understanding and very kind teacher who always go the extra mile to help his students.</div>
            </div>
            <div class="col-span-2 lg:col-span-1 hover:shadow-lg transition ease-linear bg-gray-100 shadow lg:mr-6 my-5 py-6 lg:px-8 px-4">
                <div class="lg:flex items-center">
                    <img src="/assets/testimonials/kunHong.webp" alt="Kun Hong photo" class="border-2 border-gray-800 rounded-full mx-auto lg:mr-auto">
                    <div class="mt-4 lg:mt-0 lg:ml-6">
                        <h3 class="font-bold text-lg">Ea Kun Hong</h3>
                        <h4 class="mb-2">Yishun Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Only tutor that motivates me to do and learn more"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr Lai is a teacher that helps me a lot by improving my understanding in mathematics. The pace of the lessons is often just right for me. Coupled with his patience, he explains the method on solving the questions effectively and in the simplest way. His methods of using an iPad in his teaching saves a lot of time while allowing me to visualize mathematical problems easily. During the June Holidays, he goes the extra mile to also check on my homework and ensure my answers are correct. He motivates me to do more practices in order to achieve A1 for the subject.</div>
            </div>
            <div class="col-span-2 lg:col-span-1 hover:shadow-lg transition ease-linear bg-gray-100 shadow lg:ml-6 my-5 py-6 lg:px-8 px-4">
                <div class="lg:flex items-center">
                    <img src="/assets/testimonials/lukeTan.webp" alt="Luke Tan photo" class="border-2 border-gray-800 rounded-full mx-auto lg:mr-auto">
                    <div class="mt-4 lg:mt-0 lg:ml-6">
                        <h3 class="font-bold text-lg">Luke Tan</h3>
                        <h4 class="mb-2">Tanjong Katong Secondary</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Always making sure that we can effectively grasp the learning points..."</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr. Lai is a wonderful teacher! He is very patient in explaining the points to his students using his iPad, making sure that we can effectively grasp the learning points. Punctual and well-prepared, his lessons are very efficient and often designed in a way that allows students to learn and practice as much as possible. This has even allowed students such as me to learn faster than the school curriculum. Last but not least, he always helps me beyond class time to ensure that my questions are answered! Thank you, Mr. Lai!</div>
            </div>
            <div class="col-span-2 lg:col-span-1 hover:shadow-lg transition ease-linear bg-gray-100 shadow lg:mr-6 my-5 py-6 lg:px-8 px-4">
                <div class="lg:flex items-center">
                    <img src="/assets/testimonials/pranav.webp" alt="Pranav photo" class="border-2 border-gray-800 rounded-full mx-auto lg:mr-auto">
                    <div class="mt-4 lg:mt-0 lg:ml-6">
                        <h3 class="font-bold text-lg">Pranav</h3>
                        <h4 class="mb-2">Millenia Institute</h4>
                        <blockquote class="border-l-4 border-gray-800 pl-2">"Different approach to all the other teachers that I've seen"</blockquote>
                    </div>
                </div>
                <div class="mt-8 leading-relaxed">Mr Lai started to teach me in J1 for math. Initially, I lack the foundation for Mathematics (F9 in AMath and C5 for EMath during my O levels). Hence, I was lagging behind from the very beginning in my JC journey. However, thanks to Mr. Lai, it is of great joy that I am now consistently getting As for H1 math.<br><br>He always teaches from his personal notes that are succinct and easy to understand. Changing the pace depending on my learning speed often ensures that my learning takes priority by explaining every small detail to fully understand the chapter. He reinforces my learning and makes sure all my good and weak topics are covered through an abundance of questions with all levels of difficulty. Extra classes and examination papers are often also catered and given to me to make sure that I am ready for my examinations – he even answers questions outside of class time through telephone messages to ensure that I am ready for my papers. A hardworking and highly motivated teacher – it motivates me to work hard for math as well. I highly recommend all to take his classes who are struggling in math!</div>
            </div>
        </div>
    </div>
    
    <Action />
</template>

<script>
import Action from "../components/Action";

export default {
    name: "Testimonials",
    components: {
        Action
    },
    mounted() {
        document.querySelector('meta[name="description"]').setAttribute("content", 'Check out what our student score or achieved!');
    }
}
</script>

<style scoped>
@media only screen and (min-width : 320px) {
    #testimonialContainer {
        margin-top: calc(50px + 0.5rem);
    }
}

@media only screen and (min-width : 1025px) {
    #testimonialContainer {
        margin-top: calc(70px + 0.5rem);
    }
}

img {
    height: 160px;
    width: 160px;
}
</style>