<template>
    <form id="contactForm" class="mx-auto text-gray-800" @submit.prevent="sendEmail">
        <div class="flex">
            <input name="name" type="text" v-model="name" placeholder="Name" class="mb-3 mr-2 rounded-lg block w-full px-4 py-2" />
            <input name="phone" type="text" v-model="phone" placeholder="Whatsapp Number" class="mb-3 ml-2 rounded-lg block w-full px-4 py-2" />
        </div>

        <div class="flex">
            <input name="email" type="text" v-model="email" placeholder="Email" class="mb-3 mr-2 rounded-lg block w-full px-4 py-2" />
            <select name="study" v-model="study" class="mb-3 ml-2 rounded-lg block w-full px-4 py-2">
                <option value="P1-3">Primary 1-3</option>
                <option value="P4-6">Primary 4-6</option>
                <option value="Sec1/2-Express">Sec 1/2 Express (G3)</option>
                <option value="Sec1/2-NA/NT">Sec 1/2 NA (G2) / NT (G1)</option>
                <option value="Sec3/4-Express">Sec 3/4 Express (G3) EMath AMath</option>
                <option value="Sec3/4/5-NA/NT">Sec 3/4/5 NA (G2) / NT (G1)</option>
                <option value="JC-H1/H2">Junior College H1/H2</option>
                <option value="Private">Private Candidates</option>
                <option value="Others">Others</option>

            </select>
        </div>

        <textarea id="message" v-model="message" name="message" placeholder="Share with us how else we can help you today!" rows="8" class="rounded-lg mt-2 block w-full px-4 py-2" />

        <div class="text-center mt-8">
            <p v-show="!submitted" class="text-red-500 text-sm text-center my-5"><i v-show="errorMessage" class="fas fa-exclamation mr-2"></i>{{ errorMessage }}</p>
            <p v-show="submitted" class="text-green-500 text-sm text-center my-5">Your message has been sent. We will get back to you shortly.</p>
            <button v-if="!submitted" @click="handleSubmit" class="text-white border-white border hover:text-black hover:bg-white transition ease-linear px-6 py-1 text-lg rounded-lg">Sign up now!</button>
            <button v-if="submitted" class="border-white border text-black bg-white transition cursor-not-allowed ease-linear px-6 py-1 text-lg rounded-lg">Submitted</button>
        </div>
    </form>
</template>

<script>
import validator from 'validator';

async function sendMail ({name, study, phone, email, message}) {
  const mailData = {
    from: 'contact@caine.sg',
    to: 'mathvigilante@gmail.com',
    replyTo: email,
    subject: `[New Enquiry!] Message from ${name} — ${study}`,
    text: `You have received a message from ${name} - ${phone} - ${email}. Message: ${message}`,
    html: `
      <p>Dear Math Vigilante,</p>
      <p>You got a new message from ${name}.</p>
      <p>Their email is ${email}</p>
      <p>Phone number is ${phone}</p>
      <p>Enquiring about ${study}</p>
      <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${message}</p>
      <p>Best wishes,<br>Caine Studios</p>
    `
  }

  const res = await fetch("https://www.caine.sg/api/contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(mailData)
  })

  return res.status === 200
}

export default {
    name: "ContactForm",
    data() {
        return {
            submitted: false,
            name: "",
            phone: "",
            email: "",
            study: "P1-3",
            message: "",
            errorMessage: ""
        }
    },
    methods: {
        async sendEmail() {
            if (this.name === "") {
                this.errorMessage = "Please enter your name.";
            } else if (!validator.isEmail(this.email)) {
                this.errorMessage = "Please enter a valid email.";
            } else if (this.phone === "") {
                this.errorMessage = "Please enter your phone number.";
            } else if (this.message === "") {
                this.errorMessage = "Do enter a message."
            } else {
              if (await sendMail(this)) {
                this.name = "";
                this.phone = "";
                this.study = "";
                this.email = "";
                this.message = "";

                this.submitted = true;
              } else {
                this.errorMessage = "Your message failed to send. Try contacting us at mathvigilante@gmail.com or 80426428 instead."
              }
            }
        }
    }
}
</script>

<style scoped>

</style>